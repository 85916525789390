import { Container } from '@components'
import { OurPolicy } from '@pages/components'
import React, { FC } from 'react'

interface IPrivacyPolicy {}

const PrivacyPolicy: FC<IPrivacyPolicy> = () => {
  return (
    <Container>
      <div className={'banner-terms'}>
        <div className="banner-terms__title"> TourDay Golf</div>
        <div className="banner-terms__subtitle">Privacy Policy</div>
      </div>
      <OurPolicy.PrivacyPolicyContent />
    </Container>
  )
}

export default PrivacyPolicy
